import React from "react"
import SEO from '../../components/seo'

import Osirase from '../../layouts/osirase'

export default () => (


  <Osirase>

  	<SEO title="ご注文完了"/>

	<h1>ご注文完了</h1>

	<p>振込先を明記した、確認のメールを送信しました。<br/>
	数分で届きますので、<br/>
	<strong>迷惑メールフォルダも含めて、受信をチェックしてください</strong>。</p>

	<p>万一、半日以上経っても届かない場合は、<br/>
	ご入力いただいたアドレスが間違っているか、<br/>
	通信障害が考えられます。</p>

	<p>ページの下部にあるメールアドレスまでご連絡いただくか、<br/>メールマガジンに返信する形でご連絡いただければ、対処いたします。</p>

  </Osirase>

)
